import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TitleBar } from '~/components/TitleBar';
import { LoginInputFields } from '~/components/pages/login/LoginInputFields';
import { Feature, featureStatusService } from '~/services/featureStatusService';
import { inputValidationService } from '~/services/inputValidationService';
import { register } from '~/services/loginService';

export function Register(): JSX.Element {
    const history = useHistory();

    if (!featureStatusService.isEnabled(Feature.UserRegistration)) {
        // Redirect to login page if registration is disabled
        history.push('/login');
    }

    const query = new URLSearchParams(useLocation().search);
    const [emailField, setEmail] = useState('');
    const [passwordField, setPassword] = useState('');
    const [error, setError] = useState('');

    const btnRegisterOnClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        if (emailField.length === 0) {
            setError('Please enter a valid email address');
            return;
        }

        const passwordCheck = inputValidationService.isPasswordValid(passwordField);
        if (!passwordCheck.pass) {
            setError(passwordCheck.failDescription!);
            return;
        }

        const { success, reason } = await register(emailField, passwordField);
        if (!success) {
            setError(reason!);
            return;
        }

        const returnAddress = query.get('returnAddress');
        history.push(returnAddress ?? '/home');
    };

    const btnLoginOnClick = () => history.push('/login');

    return (
        <>
            <TitleBar />
            <div className='with-title-bar-content'>
                <h1>Positive Places Register</h1>
                <form>
                    <LoginInputFields
                        emailField={emailField}
                        setEmail={setEmail}
                        passwordField={passwordField}
                        setPassword={setPassword}
                    />
                    <br />
                    <button type='submit' className='loginInput' onClick={btnRegisterOnClick}>
                        Register
                    </button>
                    <br />
                    <button type='button' className='loginInput' onClick={btnLoginOnClick}>
                        Existing User? Login.
                    </button>
                    <br />
                    <label className='lblError'>{error}</label>
                </form>
            </div>
        </>
    );
}
