import firebase from 'firebase/app';
import 'firebase/firestore';
import { render } from 'react-dom';
import { Router } from '~/components/routers/Router';
import { firebaseConfig } from '~/firebaseConfig';
import { featureStatusService } from '~/services/featureStatusService';

export async function load(): Promise<void> {
    firebase.initializeApp(firebaseConfig());

    const featureToggles = await firebase.firestore().collection('featureToggle').doc('toggles').get();
    featureStatusService.init(featureToggles.data() as Record<string, boolean>);

    const root = document.getElementById('root');
    render(Router(), root);
}
