import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { TitleBar } from '~/components/TitleBar';
import { login, setFirebasePersistence } from '~/services/loginService';
import { LoginInputFields } from '~/components/pages/login/LoginInputFields';
import { Feature, featureStatusService } from '~/services/featureStatusService';

export function Login(): JSX.Element {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const [emailField, setEmail] = useState('');
    const [passwordField, setPassword] = useState('');
    const [error, setError] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const btnLoginOnClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        const { success, reason } = await login(emailField, passwordField);
        if (success) {
            const returnAddress = query.get('returnAddress');
            history.push(returnAddress ?? '/home');
        } else {
            setError(reason!);
        }
    };

    const btnRegisterOnClick = () => history.push('/login/register');

    const toggleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;

        setRememberMe(newValue);
        setFirebasePersistence(newValue);
    };

    const registerButton = featureStatusService.isEnabled(Feature.UserRegistration)
        ? <button type='button' className='loginInput' onClick={btnRegisterOnClick}>New User? Register</button>
        : <></>;

    return (
        <>
            <TitleBar />
            <div className='with-title-bar-content'>
                <h1>Positive Places Login</h1>
                <form>
                    <LoginInputFields
                        emailField={emailField}
                        setEmail={setEmail}
                        passwordField={passwordField}
                        setPassword={setPassword}
                    />
                    <br />
                    <input
                        id='ckbRememberMe'
                        type='checkbox'
                        checked={rememberMe}
                        onChange={toggleRememberMe}
                    />
                    Remember Me
                    <br />
                    <button type='submit' className='loginInput' onClick={btnLoginOnClick}>
                        Login
                    </button>
                    <br />
                    {registerButton}
                    <br />
                    <label className='lblError'>{error}</label>
                </form>
            </div>
        </>
    );
}
