import * as React from 'react';
import { TitleBar } from '~/components/TitleBar';

interface DummyPageProps {
    pagePath: string;
}

export function Dummy({ pagePath }: DummyPageProps): JSX.Element {
    return (
        <>
            <TitleBar />
            <div className='with-title-bar-content'>
                <h1>This page is in development</h1>
                <p>
                    <b>Check back soon</b>
                </p>
                <p>
                    {`Page: ${pagePath}`}
                </p>
            </div>
        </>
    );
}
