import * as React from 'react';
import { Link } from 'react-router-dom';

export function TitleBar(): JSX.Element {
    return (
        <div className='title-bar'>
            <h1 className='title-bar-item'>
                Positive Places
            </h1>
            <Link to='/home' className='title-bar-item title-bar-button'><b>Home</b></Link>
            <Link to='/review/leave' className='title-bar-item title-bar-button'><b>Leave a Review</b></Link>
            <Link to='/review/search' className='title-bar-item title-bar-button'><b>Search for Reviews</b></Link>
        </div>
    );
}
