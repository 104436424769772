import * as React from 'react';
import { Link } from 'react-router-dom';
import { SiteIntroduction } from '~/components/pages/homepage/SiteIntroduction';

export function Homepage(): JSX.Element {
    return (
        <>
            <h1>Positive Places</h1>
            <SiteIntroduction />
            <span className='homepage-controls'>
                <Link to='/review/leave' className='btn-like-link bbr-left'><b>Leave a Review</b></Link>
                <Link to='/review/search' className='btn-like-link'><b>Search for Reviews</b></Link>
                <Link to='/login' className='btn-like-link bbr-right'><b>Login</b></Link>
            </span>
        </>
    );
}
