import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ReviewRouter } from '~/components/routers/ReviewRouter';
import { Homepage } from '~/components/pages/homepage/Homepage';
import { LoginRouter } from '~/components/routers/LoginRouter';

export function Router(): JSX.Element {
    return (
        <div className='content'>
            <BrowserRouter>
                <Switch>
                    <Route path='/home'>
                        <Homepage />
                    </Route>

                    <Route path='/review'>
                        <ReviewRouter />
                    </Route>

                    <Route path='/login'>
                        <LoginRouter />
                    </Route>

                    <Route>
                        <Redirect to='/home' />
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
}
