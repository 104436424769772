export enum Feature {
    UserRegistration = 'USER_REGISTRATION',
}

class FeatureStatusService {
    private statuses: Partial<Record<Feature, boolean>> = {};
    private isInit = false;

    public init(features: Record<Feature, boolean>): void {
        if (this.isInit) {
            throw new Error('Cannot re-innit featureStatusService');
        }

        this.statuses = features;
        this.isInit = true;
    }

    public isEnabled(feature: Feature): boolean {
        if (!this.isInit) {
            throw new Error('Tried to read feature status before featureStatusService::init()');
        }

        return Boolean(this.statuses[feature]);
    }
}

export const featureStatusService = new FeatureStatusService();
