import React from 'react';

interface LoginInputState {
    emailField: string,
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    passwordField: string,
    setPassword: React.Dispatch<React.SetStateAction<string>>;
}

export function LoginInputFields(props: LoginInputState): JSX.Element {
    const {
        emailField,
        setEmail,
        passwordField,
        setPassword,
    } = props;

    return (
        <>
            <input
                type='text'
                value={emailField}
                id='txtEmail'
                className='loginInput'
                placeholder='Email Address'
                onChange={(event) => setEmail(event.target.value)}
            />
            <br />
            <input
                type='password'
                value={passwordField}
                id='tstPassword'
                className='loginInput'
                placeholder='Password'
                onChange={(event) => setPassword(event.target.value)}
            />
        </>
    );
}
