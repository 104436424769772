/* eslint-disable max-len */
import * as React from 'react';

export function SiteIntroduction(): JSX.Element {
    return (
        <section className='site-introduction'>
            <b>
                <p>I have set this site up to recognize excellent service.</p>
                <p>Over the years I have experienced many instances of businesses providing excellent service or outstanding products. Often this will be as a result of the efforts of an individual employee or perhaps a team who sometimes work behind the scenes and are not seen.  The chefs in the kitchen who produce excellent food, the person in the supermarket who goes out of their way to find something or the plumber who goes the extra mile to fix a problem.</p>
                <p>I always try to recognize good service by saying thank you because I appreciate the hard work that it takes to consistently deliver good service.  As someone who appreciates excellent service I have two objectives.  The first is to seek out those businesses that provide it and the second is to recognize it.</p>
                <p>There are many websites providing review services which are cluttered with negative reviews.  I am not interested in those.  What I am interested in is positive experiences, both recognizing them and seeking them out so I have set up this website to enable me to acknowledge examples of outstanding service – and to publicly say thank you to those who have provided it.</p>
                <p>That was my initial objective but I am also keen to hear about other people’s positive experiences so please feel free to use the site to recognize examples of excellent service.</p>
                <p>If you choose to give a review you will be asked to give details of the business you are reviewing along with a score out of 100. You will also be able to provide commentary on the service you have received.  </p>
                <p>Not all reviews will appear on the site – only the best will appear and they will be time limited as the provision of good service some years ago does not necessarily indicate current standards.</p>
                <p>I hope you find the site to be helpful and informative.</p>
                <p className='signature'>- Michael Watson</p>
            </b>
        </section>
    );
}
