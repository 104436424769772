/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

function prodConfig() {
    return {
        apiKey: 'AIzaSyC36ID5zNSBSDpiOYKgC3UCEt1fihwA_pU',
        authDomain: 'positiveplaces.firebaseapp.com',
        projectId: 'positiveplaces',
        storageBucket: 'positiveplaces.appspot.com',
        messagingSenderId: '479602705620',
        appId: '1:479602705620:web:e8fbcf86b94729f7cba741',
    };
}

export function testingFirebaseConfig() {
    return {
        apiKey: 'AIzaSyD85OxjBsL1z7VE-rWYCByOYh_4-4gtJbk',
        authDomain: 'positiveplacestest.firebaseapp.com',
        projectId: 'positiveplacestest',
        storageBucket: 'positiveplacestest.appspot.com',
        messagingSenderId: '547619792323',
        appId: '1:547619792323:web:e41f900a55adaa228bc976',
    };
}

export function firebaseConfig() {
    const isProduction = process.env.NODE_ENV === 'production';

    return isProduction ? prodConfig() : testingFirebaseConfig();
}
