import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login } from '~/components/pages/login/Login';
import { Register } from '~/components/pages/login/Register';

export function LoginRouter(): JSX.Element {
    return (
        <Switch>
            <Route path='/login/register'>
                <Register />
            </Route>

            <Route path='/login'>
                <Login />
            </Route>
        </Switch>
    );
}
