import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Dummy } from '~/components/Dummy';

export function ReviewRouter(): JSX.Element {
    return (
        <Switch>
            <Route path='/review/leave'>
                <Dummy pagePath='review/leave' />
            </Route>

            <Route path='/review/search'>
                <Dummy pagePath='review/search' />
            </Route>

            <Route>
                <Redirect to='/review/search' />
            </Route>
        </Switch>
    );
}
