import firebase from 'firebase/app';
import 'firebase/auth';

export interface LoginResult {
    success: boolean;
    reason?: string;
}

export async function login(email: string, password: string): Promise<LoginResult> {
    try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        return { success: true };
    } catch {
        return {
            success: false,
            reason: 'Invalid username or password. Try again',
        };
    }
}

export async function register(email: string, password: string): Promise<LoginResult> {
    try {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
        return { success: true };
    } catch (e) {
        return {
            success: false,
            reason: e.message,
        };
    }
}

export function setFirebasePersistence(shouldRememberUser: boolean): void {
    if (shouldRememberUser) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    } else {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    }
}
