export enum ValidationFailReason {
    PasswordLength,
    PasswordNoUpperLowerAndNumber,
}

const FAIL_REASON_DESCRIPTIONS = new Map([
    [ValidationFailReason.PasswordLength, 'Invalid Password. Password must be 8 characters or more'],
    [ValidationFailReason.PasswordNoUpperLowerAndNumber, 'Invalid Password. Password must include one upper case, one lower case and one number'],
]);

export interface ValidationResult {
    pass: boolean;
    reason?: ValidationFailReason;
    failDescription?: string;
}

const CONTAINS_NUMBER_REGEXP = /\d+/;

class InputValidationService {
    public isPasswordValid(password: string): ValidationResult {
        const isValidLength = password.length >= 8;
        if (!isValidLength) {
            return this.buildValidationResult(false, ValidationFailReason.PasswordLength);
        }

        const containsLowerCase = password.toUpperCase() !== password;
        const containsUpperCase = password.toLowerCase() !== password;
        const containsNumber = CONTAINS_NUMBER_REGEXP.test(password);
        if (!containsLowerCase || !containsUpperCase || !containsNumber) {
            return this.buildValidationResult(false, ValidationFailReason.PasswordNoUpperLowerAndNumber);
        }

        return this.buildValidationResult(true);
    }

    private buildValidationResult(pass: boolean, reason?: ValidationFailReason): ValidationResult {
        const failDescription = FAIL_REASON_DESCRIPTIONS.get(reason!);

        return {
            pass,
            reason,
            failDescription,
        };
    }
}

export const inputValidationService = new InputValidationService();
